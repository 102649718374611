<template>
    <v-col>
        <s-crud add edit height="auto" 
            :filter="filter" 
            remove 
            title="Tipo Corte"
            @save="save($event)"
            :config="config">
            <template v-slot:filter>
                <v-col>
                    <v-row justify="center">
                        <v-col lg="3">
                            <s-select-definition clearable 
                                v-model="filter.TypeCut" 
                                :def="1463"
                                label="Tipo de Corte">

                            </s-select-definition>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row>
                        <v-col>
                            <s-select-definition                                 
                                :def=1463 label="Estado" 
                                v-model=props.item.TypeCut>
                            </s-select-definition>  
                        </v-col>
                        <v-col>
                            <s-text label="Descripcion"
                            v-model="props.item.TcpDescription"
                            ref="TcpDescription"></s-text>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-col>
</template>

<script>
import _sFrzTypeCutPacking from '@/services/FrozenProduction/FrzTypeCutPackingService.js';
export default{
    data:() => ({
        filter: {TypeCut: 0},
        config: {
            model:{
                TcpID: "ID"
            },
            service: _sFrzTypeCutPacking,
            headers:[
                { text: "ID", value: "TcpID", sortable: false, width: 10 },
                { text: "Tipo de Corte", value: "TypeCutName", sortable: false },
                { text: "Variante", value: "TcpDescription", sortable: false },
            ]
        }
    }),
    methods:{
        save(item){

            if(item.TcpDescription == ""){
                this.$fun.alert("Ingrese descripcion", "warning");
                this.$refs.TcpDescription.focus();
                return;
            }        
            item.UsrID = this.$fun.getUserID();  
            item.save();
        }
    }
}
</script>