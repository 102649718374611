import Service from "../Service";

const resource = "typecutpacking/";

export default {
    save(cmp, requestID){
        return Service.post(resource + "save", cmp,{
            params: {requestID: requestID},
        });
    },
    pagination(dtr, requestID){
        return Service.post(resource + "pagination", dtr,{
            params:{requestID: requestID},
        });
    },
    list(fas, requestID) {
        return Service.post(resource + "getlist", fas, {
          params: { requestID: requestID },
        });
    },
    

}